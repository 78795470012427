<template>
  <div>
    <Topbar></Topbar>
    <div class="pc-title">首页>我的订单>订单详情</div>
    <div class="content">
      <div class="buzhou" v-if="end">
        <div
          class="buzhou-item"
          v-for="(item, index) in buzhoulist"
          :key="index"
        >
          <div class="buzhou-item-left">
            <div class="buzhou-item-img">
              <img
                :src="index >= detail.Status + 1 ? item.img : item.img2"
                alt=""
              />
            </div>
            <!-- 需要根据状态换图片 暂时更换字体颜色 -->
            <div
              class="buzhou-item-font"
              :style="{ color: index == detail.Status ? 'red' : '#000' }"
            >
              {{ item.text }}
            </div>
          </div>
          <div
            :class="[index >= detail.Status ? ' line2' : ' line']"
            v-if="index != buzhoulist.length - 1"
          ></div>
        </div>
      </div>

      <div class="status" v-if="end">
        <div class="status-top">
          <div class="status-icon">
            <img src="../../../assets/images/redgantan.png" alt="" />
          </div>
          <div class="status-font" v-if="detail.Status == 0">
            当前状态：等待买家付款
          </div>
          <div class="status-font" v-else-if="detail.Status == 1">
            当前状态：等待卖家发货
          </div>
          <div class="status-font" v-else-if="detail.Status == 2">
            当前状态：等待收货
          </div>
          <div
            class="status-font"
            v-else-if="
              detail.Status == 3 && detail.OrderDetailList[0].IsEvaluation != 1
            "
          >
            当前状态：待评价
          </div>
          <div
            class="status-font"
            v-else-if="detail.OrderDetailList[0].IsEvaluation == 1"
          >
            当前状态：交易成功
          </div>
        </div>
        <!-- <div class="status-two">
          还有
          <div class="status-time">{{ time }}</div>
          进行付款，若未及时付款，系统将自动取消订单
        </div> -->
        <div class="status-button">
          <div
            class="Th"
            v-if="detail.Status == 2"
            @click="applyservice(orderId)"
          >
            退款/退货
          </div>
          <div class="esc" v-if="detail.Status == 0" @click="fukuan(detail)">
            立即付款
          </div>
          <div class="esc" v-if="detail.Status == 0" @click="cancel()">
            取消订单
          </div>
          <!-- <div class="esc" v-else-if="detail.Status == 1">提醒发货</div> -->
          <!-- <div class="esc"  v-if="detail.Status == 3" @click="gopingjia(orderId)">评价</div> -->
          <div
            class="refund"
            v-if="detail.Status == 1 || detail.Status == 3"
            @click="applyservice(orderId)"
          >
            退款
          </div>
        </div>
      </div>

      <!-- <div class="status" v-if="!end">
        <div class="status-top">
          <div class="status-icon">
            <img src="../../../assets/images/redgantan.png" alt="" />
          </div>
          <div class="status-font">当前状态：订单关闭</div>
        </div>
        <div class="status-two">关闭原因：用户主动关闭,我不想买了</div>
       
      </div> -->
      <div class="infor">
        <div class="infor-title">订单信息</div>
        <div class="infor-detail">
          <div class="infor-detail-item">
            <div class="people">
              收货人：{{ detail.ContactName }} {{ detail.ContactPhone }}
            </div>
            <div class="address mr">收货地址：{{ detail.Address }}</div>
            <div class="num mr">订单单号：{{ detail.ShopOrderNumber }}</div>
            <div class="price mr">
              支付金额：
              <div class="money">¥{{ detail.PayMoney }}</div>
            </div>
            <div class="time mr">下单时间：{{ detail.PayTime }}</div>
            <div class="jifen mr">获取积分:{{ detail.GivePoint }}</div>
          </div>
        </div>
      </div>

      <div class="goods" v-if="this.statsu != 2">
        <div class="infor-title">
          <div
            class="infor-title-item"
            v-for="(item, index) in titlelist"
            :key="index"
          >
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="order_info" v-for="(item, i) in orderList" :key="i">
          <div class="info_num">
            <span
              >{{ item.Company ? item.Company + ": " : ""
              }}{{ item.LogisticsCode }}</span
            >
            <span class="info_status">{{
              item.Status == 0 ? "待收货" : "已收货"
            }}</span>
          </div>
          <div
            class="info_detail"
            v-for="(goods, j) in item.LogisticsDetailList"
            :key="j"
          >
            <div class="itemleft">
              <img :src="goods.ImageUrl" alt="" />
              <div class="detail_content">
                <div>
                  {{
                    goods.GoodsName.length > 45
                      ? goods.GoodsName.substr(0, 45) + "..."
                      : goods.GoodsName
                  }}
                </div>
                <div class="spec">
                  <span>{{ goods.SpecName }}</span>
                </div>
              </div>
            </div>

            <div class="info_btn" v-if="item.Status == 0">
              <div class="shouhuo" @click="confirm(item.Id)">确认收货</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 待收货 -->
      <div class="goods" v-if="this.statsu == 2">
        <div class="infor-title">
          <div
            class="infor-title-item2"
            v-for="(item, index) in titlelist2"
            :key="index"
          >
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="order_info2" v-for="(item, i) in orderList" :key="i">
          <div class="info_num">
            <div class="wlgs">{{ item.Company }}</div>
            <div class="wldh">{{ item.LogisticsCode }}</div>
            <span class="info_status2">{{
              item.Status == 0 ? "待收货" : "已收货"
            }}</span>
          </div>
          <div
            class="info_detail"
            v-for="(goods, j) in item.LogisticsDetailList"
            :key="j"
          >
            <div class="itemleft">
              <img :src="goods.ImageUrl" alt="" />
              <div class="detail_content">
                <div>
                  {{
                    goods.GoodsName.length > 45
                      ? goods.GoodsName.substr(0, 45) + "..."
                      : goods.GoodsName
                  }}
                </div>
                <div class="spec">
                  <span>{{ goods.SpecName }}</span>
                </div>
              </div>
            </div>
            <div class="info_btn" v-if="item.Status == 0">
              <div class="shouhuo" @click="confirm(item.Id)">确认收货</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 不是待收货 -->
      <div class="goods" v-if="this.statsu != 2 && this.statsu != 3">
        <div
          class="goods-item"
          v-for="(goods, j) in detail.OrderDetailList"
          :key="j"
        >
          <div class="content-left-img">
            <img :src="goods.SpecImg" alt="" />
          </div>
          <div class="content-left-font">
            <div class="font1">{{ goods.GoodsName }}</div>
            <div class="font2">{{ goods.SpecName }}</div>
          </div>
          <!-- <div class="number">{{ item.number }}</div> -->
          <div class="content-price">￥{{ goods.GoodsPrice }}</div>
          <div class="content-num">{{ goods.GoodsCount }}</div>
          <div class="off">-¥{{ detail.DiscountMoney }}</div>
        </div>
      </div>

      <!-- 待评价 -->
      <div class="goods" v-if="this.statsu == 3">
        <div
          class="goods-item"
          v-for="(goods, j) in detail.OrderDetailList"
          :key="j"
        >
          <div class="content-left-img">
            <img :src="goods.SpecImg" alt="" />
          </div>
          <div class="content-left-font">
            <div class="font1">{{ goods.GoodsName }}</div>
            <div class="font2">{{ goods.SpecName }}</div>
          </div>
          <!-- <div class="number">{{ item.number }}</div> -->
          <div class="content-price">￥{{ goods.GoodsPrice }}</div>
          <div class="content-num">{{ goods.GoodsCount }}</div>
          <div class="off">-¥{{ detail.DiscountMoney }}</div>
          <div
            class="pj"
            v-if="goods.IsEvaluation != 1 && goods.Status == 3"
            @click="toevaluate(goods.ShopOrderId, goods.SpecId, goods)"
          >
            评价
          </div>
        </div>
      </div>

      <div class="goods-add">
        <div class="goods-content">
          <div
            :class="[
              item.important ? 'goods-content-item2' : ' goods-content-item',
            ]"
            v-for="(item, index) in addlist"
            :key="index"
          >
            <div class="goods-left">{{ item.left }}</div>
            <div class="goods-right">{{ item.right }}</div>
          </div>
        </div>
      </div>

      <!-- 继续付款 -->
      <el-dialog title="付款方式" :visible.sync="dialogVisible" width="550px">
        <div class="pay">
          <div class="type-select">
            <div
              class="type-item"
              v-for="(item, index) in typelist"
              :key="index"
              @click="change3(index, typelist)"
            >
              <div :class="[item.ischeck ? 'type-left2' : ' type-left']"></div>
              <div class="type-right">
                <img :src="item.img" alt="" />
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="pay2()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <Foter></Foter>
    <we-chat-payment
      :data="pay"
      :dialogVisible="dialogVisible2"
      v-if="dialogVisible2"
      @close="getClose()"
    ></we-chat-payment>
  </div>
</template>
<script>
import Topbar from "../../PCshop/components/topbar.vue";
import Foter from "../../PCshop/components/shopfooter.vue";
import SuS from "../../PCshop/components/suspen.vue"; //悬浮
import { queryShopOrderDetail, shopOrderDelete, queryShopLogisticsList, confirmReceipt, shopOrderPay } from "@/api/shop";
import WeChatPayment from "@/components/WeChatPayment.vue"; //微信支付组件
import { Toast, Dialog } from "vant";
export default {
  components: {
    Topbar,
    Foter,
    SuS,
    WeChatPayment
  },
  data () {
    return {
      pay: {},
      dialogVisible2: false,
      dialogVisible: false,
      status: 0,
      end: true, //默认为true，如用户取消订单则为false
      time: "1小时38分29秒",
      buzhoulist: [
        {
          text: "1.提交订单",
          img: require("../../../assets/images/buzhou1.png"),
          img2: require("../../../assets/images/buzhou1.png"),
        },
        {
          text: "2.付款成功",
          img: require("../../../assets/images/buzhou2.png"),
          img2: require("../../../assets/images/nobuzhou2.png"),
        },
        {
          text: "3.商家发货",
          img: require("../../../assets/images/buzhou3.png"),
          img2: require("../../../assets/images/nobuzhou3.png"),
        },
        {
          text: "4.确认收货",
          img: require("../../../assets/images/buzhou4.png"),
          img2: require("../../../assets/images/nobuzhou4.png"),
        },
        {
          text: "5.去评价",
          img: require("../../../assets/images/buzhou4.png"),
          img2: require("../../../assets/images/nobuzhou4.png"),
        }
      ],
      //支付方式
      typelist: [
        {
          ischeck: true,
          img: require('../../../assets/images/pld2.png')
        },
        {
          ischeck: false,
          img: require('../../../assets/images/zfb.png')
        },
        {
          ischeck: false,
          img: require('../../../assets/images/wx.png')
        },
      ],
      titlelist: [
        {
          text: "商品"
        },
        // {
        //   text: "商品编号"
        // },
        {
          text: "价格"
        },
        {
          text: "商品数量"
        },
        {
          text: "优惠"
        },
      ],
      titlelist2: [
        {
          text: "商品"
        },
        {
          text: "商品状态"
        },
        {
          text: "物流"
        },
        {
          text: "物流单号"
        },
        {
          text: "操作物流"
        },
      ],
      godslist: [
        {
          price: 160,
          num: 2,
          src: require("../../../assets/images/shopimg.png"),
          font:
            "帝品狐晶瓷画玄关装饰画轻奢现代简约北欧风抽象 几何艺术入户过道走廊墙壁挂画竖版有框画可定制",
          number: "70679353319",
          off: "-￥10"
        },
        {
          price: 170,
          num: 4,
          src: require("../../../assets/images/shopimg.png"),
          font:
            " 帝品狐晶瓷画玄关装饰画轻奢现代简约北欧风抽象 几何艺术入户过道走廊墙壁挂画竖版有框画可定制",
          number: "70679353319",
          off: "-￥10"
        }
      ],
      addlist: [
        {
          left: "商品总额：",
          right: "￥988"
        },
        {
          left: "运费：",
          right: "+￥10"
        },
        {
          left: "优惠券：",
          right: "-￥20"
        },
        {
          left: "实付款：",
          right: "￥978",
          important: true
        }
      ],
      orderId: "", //订单id
      payMethod: 0,//支付方式
      detail: {}, //商品详情对象
      orderList: [], //物流数据数组
      statsu: null
    };
  },
  mounted () {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    this.orderId = this.$route.query.orderId || 1064;
    this.statsu = this.$route.query.sts;
    this.getQueryShopOrderDetail();
    if (this.statsu == 2) {
      // this.titlelist=this.titlelist2
    }
  },
  computed: {
  },
  methods: {
    getClose () {
      this.dialogVisible2 = false;
    },
    // 选择付款方式
    change3 (idx, arr) {
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[idx], "ischeck", "true");
      this.payMethod = idx
    },
    //重新付款
    fukuan (item) {
      this.dialogVisible = true
    },
    async pay2 () {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      let parm = {
        OrderId: this.orderId,
        PayMethod: this.payMethod,
        OrderSource: "PC",
      };
      const res = await shopOrderPay(parm);
      this.dialogVisible = false
      if (res.success == true) {
        if (this.payMethod == 0) {
          //普利点支付
          {
            Toast.success({
              message: res.msg,
            })
            this.$router.push({
              path: "/PCshop/goods/goodssucc",
              query: { orderId: res.response.Id },
            });
          }
        } else if (this.payMethod == 1) {
          console.log('支付宝')
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.response.PayData;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
          setTimeout(() => {
            this.$router.push({
              path: "/PCshop/goods/goodssucc",
              query: { orderId: res.response.Id },
            });
          }, 1000)

        } else if (this.payMethod == 2) {
          //微信支付
          {
            setTimeout(() => {
              this.dialogVisible2 = true;
              this.pay.code = res.response.PayData;
              this.pay.TotalMoney = this.payMoney;
              this.pay.order = "订单";
            }, 500)
          }
        }
      } else {
        Toast.fail({
          message: res.msg,
        });
      }

    },
    //确认收货
    confirm (id) {
      console.log(id, 888)
      Dialog.confirm({
        title: "确认收货",
        message: "确定商品已收到",
      })
        .then(() => {
          this.postConfirmReceipt(id);
        })
        .catch(() => {
        });
    },
    async postConfirmReceipt (id) {
      const res = await confirmReceipt(id);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.postQueryShopLogisticsList();
        this.$router.push({
          path: "/PCshop/my/shopmy",
        });
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    // 列表数据
    async postQueryShopLogisticsList () {
      const res = await queryShopLogisticsList(this.orderId);
      this.orderList = res.response;

    },
    //去退货
    applyservice (id) {
      console.log(id, 7777)
      this.$router.push({
        path: "/PCshop/service/applyservice",
        query: { orderId: id },
      });
    },
    //去评价
    gopingjia (id, id2) {
      this.$router.push({
        path: "/PCshop/goods/evaluate",
        query: { orderId: id, specId: id2 },
      });
    },
    // 订单详情
    async getQueryShopOrderDetail () {
      const res = await queryShopOrderDetail(this.orderId);
      this.addlist[0].right = "￥" + res.response.GoodsPrice;
      this.addlist[1].right = "+￥" + res.response.Postage;
      this.addlist[2].right = "-￥" + res.response.DiscountMoney;
      this.addlist[3].right = "￥" + res.response.PayMoney;
      this.detail = res.response;
      console.log(this.detail, 5555)
      this.status = this.detail.Status
      if (this.status == 2) {
        this.postQueryShopLogisticsList(this.orderId)
      }
      if (this.detail.OrderDetailList[0].IsEvaluation == 1) {
        console.log('lalala')
        this.detail.Status = 5
      }
    },
    //取消订单
    async postShopOrderDelete () {
      const res = await shopOrderDelete(this.orderId);
      if (res.success == true) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.$router.go(-1)
      } else {
        this.$message.error(res.msg);
      }
    },
    cancel () {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消该订单吗",
      })
        .then(() => {
          this.postShopOrderDelete();
        })
        .catch(() => {
        });
    },
    cancel2 () {

    },
    //去评价
    toevaluate (id, id2) {
      this.$router.push({
        path: "/PCshop/goods/evaluate",
        query: { orderId: id, specId: id2 },
      });
    },
    // 物流列表数据
    async postQueryShopLogisticsList () {
      const res = await queryShopLogisticsList(this.orderId);
      this.orderList = res.response;
      console.log(this.orderList, 77777);
    },
  }
};
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 1100px;
  margin: 0 auto 32px;
  overflow: hidden;
}
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
.buzhou {
  .buzhou-item-left {
    width: 70px;
  }
  margin-top: 30px;
  display: flex;
  padding-left: 60px;
  .buzhou-item {
    text-align: center;
    display: flex;
    .buzhou-item-font {
      margin-top: 25px;
    }
  }
  .line {
    width: 159px;
    height: 4px;
    background: #f95e2e;
    margin-top: 20px;
  }
  .line2 {
    width: 159px;
    height: 4px;
    background: #eeeeee;
    margin-top: 20px;
  }
}

.status {
  .status-top {
    display: flex;
  }
  width: 96%;
  margin: 39px auto 21px;
  border: 1px solid #efefef;
  height: 136px;
  padding-top: 20px;
  padding-left: 20px;
  .status-icon {
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .status-font {
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
  }
  .status-two {
    margin-top: 13px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    .status-time {
      color: #f40707;
    }
  }
  .status-button {
    cursor: pointer;
    display: flex;
    margin-top: 16px;
    .pay {
      width: 80px;
      height: 30px;
      background: #f95e2e;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
    .Th {
      width: 80px;
      height: 30px;
      background: #f95e2e;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
    .esc {
      width: 80px;
      height: 30px;
      border: 1px solid #e8e7e7;
      text-align: center;
      line-height: 30px;
      margin-left: 20px;
    }
    .refund {
      width: 80px;
      height: 30px;
      background: #51b8f1;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
      margin-left: 20px;
    }
  }
}

.infor {
  height: 230px;
  background: #ffffff;
  border: 1px solid #efefef;
  width: 96%;
  margin: 20px auto 21px;
  .infor-title {
    padding-top: 15px;
    padding-left: 20px;
    height: 50px;
    color: #999999;
    font-size: 14px;
    background: #efefef;
  }
  .infor-detail {
    width: 100%;
    height: 139px;
    font-size: 14px;
    padding-top: 19px;
    padding-left: 20px;
    box-sizing: border-box;
    .price {
      display: flex;
      .money {
        color: #f40707;
      }
    }
    .mr {
      margin-top: 5px;
    }
  }
}

.goods {
  width: 96%;
  margin: 20px auto 21px;
  border: 1px solid #efefef;
  .infor-title {
    height: 50px;
    color: #333333;
    font-size: 14px;
    background: #efefef;
    display: flex;
    line-height: 50px;
  }
  .goods-item {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    padding-left: 21px;
    padding-top: 25px;
    padding-bottom: 20px;
    .content-left-img {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-left-font {
      max-width: 308px;
      margin-left: 14px;
      .font1 {
        width: 308px;
        height: 38px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .font2 {
        font-size: 12px;
        color: #999999;
        margin-top: 7px;
      }
    }
    .number {
      margin-left: 98px;
    }
    .content-price {
      min-width: 53px;
      text-align: center;
      margin-left: 51px;
    }
    .content-num {
      margin-left: 106px;
    }
    .off {
      margin-left: 107px;
    }
    .pj {
      width: 80px;
      height: 30px;
      border: 1px solid #e9e9e9;
      text-align: center;
      line-height: 30px;
      margin-left: 200px;
      margin-top: -4px;
    }
    .wuliu {
      margin-left: 112px;
    }
    .pay {
      margin-top: -6px;
      margin-left: 58px;
      width: 80px;
      height: 30px;
      background: #f95e2e;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
  }
}

.goods-add {
  width: 96%;
  height: 160px;
  margin: 20px auto 21px;
  background: #f7f7f7;
  margin-bottom: 21px;
  padding-right: 22px;
  padding-top: 10px;
  .goods-content {
    margin-left: auto;
    width: 15%;
    .goods-content-item {
      margin-top: 10px;
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
      .goods-left {
        font-weight: bold;
      }
    }
    .goods-content-item2 {
      margin-top: 10px;
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
      .goods-left {
        font-weight: bold;
      }
      .goods-right {
        font-weight: bold;
        color: #f40707;
      }
    }
  }
}
.infor-title-item:nth-child(1) {
  min-width: 400px;
  text-align: center;
}
.infor-title-item:nth-child(2) {
  min-width: 172px;
  text-align: center;
}
.infor-title-item:nth-child(3) {
  min-width: 91px;
  text-align: center;
}
.infor-title-item:nth-child(4) {
  min-width: 146px;
  text-align: center;
}
.infor-title-item:nth-child(5) {
  min-width: 129px;
  text-align: center;
}

.order_info {
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
}
.info_num {
  display: flex;
}
.info_detail {
  display: flex;
  justify-content: space-between;
  img {
    width: 60px;
    height: 60px;
  }
  .detail_content {
    margin-left: 15px;
    max-width: 308px;
    .spec {
      color: #999999;
      font-size: 14px;
    }
  }
  .info_btn {
    margin-right: 30px;
  }
  .itemleft {
    display: flex;
  }
}
.shouhuo {
  background: #f95e2e;
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
}

.pay {
  margin-top: -30px;
  width: 40%;
  .type-select {
    .type-item {
      cursor: pointer;
      display: flex;
      margin-top: 40px;
      .type-left {
        width: 18px;
        height: 18px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 50%;
      }
      .type-left2 {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-image: url("../../../assets/images/pay-select.png");
        background-repeat: no-repeat;
      }
      .type-right {
        margin-left: 52px;
        margin-top: -5px;
      }
    }
  }
}

.order_info2 {
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
  position: relative;
  .info_status2 {
    position: absolute;
    left: 464px;
    top: 25px;
  }
  .wlgs {
    position: absolute;
    left: 590px;
    top: 25px;
  }
  .wldh {
    min-width: 120px;
    text-align: center;
    position: absolute;
    left: 733px;
    top: 25px;
  }
}
.infor-title-item2:nth-child(1) {
  min-width: 400px;
  text-align: center;
}
.infor-title-item2:nth-child(2) {
  min-width: 172px;
  text-align: center;
}
.infor-title-item2:nth-child(3) {
  min-width: 91px;
  text-align: center;
}
.infor-title-item2:nth-child(4) {
  min-width: 255px;
  text-align: center;
}
.infor-title-item2:nth-child(5) {
  min-width: 129px;
  text-align: center;
}
</style>
